@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap");

.subtitle-promos {
  font-family: "Josefin Slab", serif !important;
  font-style: italic !important;
  color: #6f6f6f !important;
  font-size: 2.5em;
}

.card-height {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 700px;
}

.title {
  color: #3d568a;
  font-size: 24px;
  font-weight: 600;
}

.noites {
  color: #6f6f6f;
  font-size: 20px;
  font-weight: 600;
}

.descricao {
  color: #6f6f6f;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.valorTotal {
  color: #6f6f6f;
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
}

.valorTotalDestaque {
  color: #3d568a;
  font-size: 20px;
  font-weight: 600;
}

.valorParcela {
  color: #6f6f6f;
  font-size: 18px;
  font-weight: 400;
}

.valorParcelaDestaque {
  color: #3d568a;
  font-size: 32px;
  font-weight: 800;
}

.promos-btnAction {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  text-decoration: none;
  color: #ffffff;
  background: #4eb13d;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  transition: 0.2s ease-in;
  border: 1px solid #4eb13d;
  margin-top: 20px;
  padding: 0 20px;
  border-radius: 5px;
}

.promos-btnAction:hover {
  text-decoration: none !important;
  color: #ffffff !important;
  background: #49a339;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
}

.promocoes-pacote-image {
  max-height: 220px;
  object-fit: cover;
}

@media screen and (min-width: 992px) {
  .promocoes-pacote-image {
    min-height: 220px;
  }
}