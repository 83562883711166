.appears-animation {
    animation: appears 1s ease-in normal;
    transition: all 2s ease-in;
}

@keyframes appears {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}