.downloadButton {
  background-color: #f07939;
  color: #fff;
  font-size: 10px;
  border-radius: 3px;
  text-decoration: none;
}

.downloadButton:hover {
  background-color: #e05d1b;
  text-decoration: none;
  color: #fff;
}
