@import "~react-image-gallery/styles/css/image-gallery.css";

#btn-horarios-plantao { 
  background-color: #fe7a15;
  color: #fff;
  margin-top: 2rem;
}

#btn-horarios-plantao:hover {
  background-color: #ef6b06;
  color: #fff;
  border: 1px solid transparent;
}

#team-card-container {
  animation: team-appears 300ms ease-in-out normal;
  transition: all 200ms ease-in-out;
}

@keyframes team-appears {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

a:hover {
  text-decoration: none !important;
  color: inherit !important
}

.azul {
  color: #0d4990 !important;
}

.azul-claro {
  color: #4b8996;
}

.amarelo {
  color: #fe7a15;
}

.bg-light {
  background-color: #eef4f5 !important;
}

.blank-default-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95vh;
}

.btn-consulte-b2b {
  background-color: #0028a5;
  border-color: #0028a5;
  color: #fcfcfc;
  text-decoration: none;
  outline: none;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 25em;
  padding: 10px 2rem;
}

/* ========= NAVIGATION  */
.navbar {
  font-family: inherit !important;
  font-size: 14px;
  user-select: none;
  transition: all 0.3s ease-in-out;
}

.navbar-brand img {
  width: 180px !important;
  height: 50px;
  object-fit: cover;
}

.navbar-gradient {
  background: rgb(0, 9, 62);
  background: linear-gradient(90deg, rgba(0, 9, 62, 1) 0%, rgba(0, 16, 115, 1) 100%);
}

.navbar-toggler-icon {
  background-color: #f6f6f6de;
  margin-bottom: .5rem;
  font-size: 16px;
}

#navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-inline: 1%;
}

@media screen and (min-width: 1200px) {
  #navbar-container {
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    width: 1100px;
  }
}

@media screen and (min-width: 1540px) {
  #navbar-container {
    justify-content: space-between;
    margin: 0 auto;
    width: 1480px;
  }
}

.scroll-active {
  box-shadow: rgba(33, 35, 38, 0.3) 0px 10px 10px -10px;
  animation: show-shadow 0.5s ease-in-out normal;
}

@keyframes show-shadow {

  0%,
  100% {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }

  50% {
    box-shadow: rgba(50, 50, 93, 0.123) 0px 13px 27px -5px, rgba(0, 0, 0, 0.1) 0px 8px 16px -8px;
  }
}

.navbar .nav-link {
  display: flex;
  align-items: center;
}

.navbar .nav-item-icon {
  width: 1rem;
  height: 1rem;
  color: #f6f6f6;
  filter: brightness(0) invert(1);
  margin-right: .3rem;
}

.nav-item-mobile-text {
  color: #f6f6f6 !important;
  filter: brightness(0) invert(1);
}

.navbar .nav-item-text {
  color: #f6f6f6;
  word-break: break-word;
  font-weight: bold;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.navbar .dropdown-item {
  font-size: 14px !important;
}

.navbar svg {
  color: #738799 !important;
}

/* =================== */

#forms {
  background-color: #ffffff;
}

#ofertas .carousel-control-prev,
#ofertas .carousel-control-next {
  padding-top: 120px;
  width: 100px;
  height: 260px;
}

#ofertas div.img-fluid-container {
  flex: 1;
}

#ofertas img {
  height: 178px
}

/* ======== Carousel Arrow start ========= */
.ofertas-carousel-arrow-container {
  display: grid;
  place-content: center;
  font-size: 35px;
  color: #748798;
  cursor: pointer;
  animation: appears 3s ease-in-out normal;
}

.ofertas-carousel-arrow-container .arrow-icon:hover {
  color: #0d4991;
  transform: scale(1.2);
  transition: all 100ms ease-in-out;
}

.fa-chevron-left {
  position: relative;
  right: 30px;
}

.fa-chevron-right {
  position: relative;
  left: 30px;
}

@keyframes appears {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1100px) {
  .ofertas-carousel-arrow-container {
    display: none;
  }

}

/* ======== Carousel Arrow end ========= */

.grade-imagens div.img-fluid-container {
  height: 100%;
}

.grade-imagens img.img-fluid {
  height: 350px !important;
  object-fit: cover;
}

.wooba {
  background-image: url(../../../src/images/gallery/bg03.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.carousel-caption h5 {
  color: #fff;
  font-size: 42px;
}

.slides p {
  font-size: 18px;
  font-family: "Titillium Web", "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

@media screen and (max-width: 540px) {
  .slides {
    margin-top: 40px;
  }

  .carousel-caption h5 {
    font-size: 32px;
  }

  .carousel-item {
    height: fit-content;
  }

  .carousel-item img.d-block {
    height: fit-content;
  }

  .slides p {
    font-size: 18px;
  }
}

@media screen and (min-width: 1400px) {
  .carousel-item>img {
    width: 120% !important;
  }

  .carousel-caption {
    padding: 40px 0;
  }
}

.slides .carousel-control-prev,
.slides .carousel-control-next {
  top: 20vh;
}

.btn-primary:hover {
  background-color: #042140;
}

.btn-secondary,
.btn-secondary:hover {
  text-decoration: none;
  background-color: #fe7a15;
  border-color: #fe7a15;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer !important;
  font-family: "Titillium Web", "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

.btn-preto,
.btn-preto:hover {
  text-decoration: none;
  background-color: #000;
  border-color: #000;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer !important;
  font-family: "Titillium Web", "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

main {
  padding-top: 120px;
}

.destinos header {
  padding: 5vw 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 80px;
}

.destinos header h1 {
  color: #fff !important;
}

.destinos img {
  height: 250px;
}

#pacotes .card {
  border: 1px solid #eaeaea;
}

.card img,
.card,
.bg-voyage-gradient {
  border-radius: 20px
}

#pacotes .badge {
  margin-right: 5px;
}

#pacotes .card-header {
  font-size: 22px;
}

#pacotes h3 {
  font-size: 24px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #dceaed;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
  display: block;
}

@media screen and (max-width: 540px) {
  .dropdown-submenu {
    display: block !important;
    float: left;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    position: relative !important;
    background-color: #f6f6f6;
  }

  .carousel-caption .bg-primary {
    font-size: 16px;
  }
}

/* Mobile menu styles  */
.dropdown-mobile {
  float: left;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  position: relative !important;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  font-family: inherit !important;
  font-size: 14px;
}

.destinos-categoria {
  padding: .4rem 0;
  position: relative;
}

.dropdown-toggle-mobile {
  margin: 1rem;
}

.mobile-item {
  background-color: #f6f6f6;
  margin: .1em 0;
  padding: .4em 0;
  position: relative;
}

.outros {
  background-size: cover;
  background-position: center;
  color: #fff;
}

.loja-img {
  height: 180px;
  overflow: hidden;
}

.whatsapp,
.whatsapp:hover {
  width: 70px;
  height: 70px;
  color: #fff !important;
  float: right;
  margin: 0 20px 20px 0;
  font-size: 25px;
  display: flex;
  align-items: center;
  align-content: center;
  align-self: center;
}

.remove-whatsapp .whatsapp {
  display: none !important;
}

.sac-icon {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

#carouselTestimonials .carousel-item {
  min-height: 520px;
}

.badge {
  white-space: normal;
  line-height: 1;
}

h1.badge {
  font-size: 36px;
}

.bg-gradient-brasil {
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#0d4990),
      to(#2b8004));
  background: -o-linear-gradient(left, #0d4990, #2b8004);
  background: linear-gradient(90deg, #0d4990, #2b8004);
}

.bg-voyage-gradient {
  /* height:50%; */
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(57, 67, 77, 0)),
      to(#0d4990));
  background: -o-linear-gradient(top, rgba(57, 67, 77, 0) 0%, #0d4990 100%);
  background: linear-gradient(180deg, rgba(57, 67, 77, 0) 0%, #0d4990 100%);
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #042140;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #b2cae3;
}

.padding-promos {
  padding: 0 50px;
}

/* autenticação */

._37_FJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 40px 0;
}

#carouselTestimonials .card-img-overlay {
  z-index: -9999 !important;
}

/* carrossel */
.carousel-dot li {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  box-shadow: 0 0 2px 2px rgba(51, 51, 51, 0.5);
  overflow: hidden;
}

.carousel-dot li:hover {
  box-shadow: 0 0 1px 3px rgba(117, 151, 186, 0.5);
}

.carousel-dot li button {
  opacity: 0;
}

.carousel-dot li:hover button {
  visibility: visible;
}

.carousel-dot .slick-active,
.carousel-dot .slick-active,
.carousel-dot .slick-active {
  justify-content: center;
  box-shadow: 0 0 1px 3px rgba(117, 151, 186, 0.5);
}

.carousel-dot .slick-active button {
  opacity: 1;
  background-color: #042140;
}

.ql-align-center {
  text-align: center;
}

img.banner {
  width: 100vw !important
}

@media (max-width:450px) {
  img.banner {
    transform: scale(1.8);
    padding: 40px 0
  }
}

@media (max-width:450px) {
  img.banner {
    transform: scale(1.8);
    padding: 40px 0
  }
}

/* pacotes home gradient row  */
.gradient-base-dark-blue {
  position: relative;
}

.gradient-base-dark-blue::before {
  position: absolute;
  background: rgb(0,9,62);
  content: "";
  height: 100%;
  width: 1000%;
  margin-left: -1000%;
}

.gradient-row {
  background: rgb(0,9,62);
  background: linear-gradient(90deg, rgba(0,9,62,1) 0%, rgba(0,16,115,1) 100%); 
}

.gradient-base-dark-blue-right {
  position: relative;
}

.gradient-base-dark-blue-right::before {
  position: absolute;
  background: rgb(0, 9, 62);
  content: "";
  height: 100%;
  width: 1000%;
  margin-right: -1000%;
  z-index: -1;
}

.gradient-row-footer {
  background: rgba(0, 16, 115);
  background: linear-gradient(90deg, rgba(0, 16, 115, 1) 0%, rgba(0, 9, 62, 1) 100%);
}

/* pacotes home */
.pacote-box {
  border: 2px solid transparent;
  background-color: #fff;
  box-shadow: 4px 4px 4px rgba(233, 233, 233, 0.5);
  border-radius: 20px;
}

.pacote-box:hover {
  border: 2px solid #fe7a15;
  border-radius: 30px;
}

.pacote-box .pacote-img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 180px;
  overflow: hidden;
}

.pacote-box .pacote-img img {
  height: 180px !important;
  width: 100% !important
}

@media (max-width:576px) {
  .pacote-box .pacote-img {
    height: 130px;
  }

  .pacote-box .pacote-img img {
    height: 130px !important;
    width: 100% !important
  }
}

.pacote-box img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.pacote-box:hover img {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.pacote-box:hover .pacote-descricao,
.pacote-box a:hover {
  text-decoration: none !important;
}

.pacote-box .pacote-info {
  position: absolute;
  margin-top: -40px;
}

.pacote-box .pacote-descricao {
  font-size: 14px;
  color: #222;
}

/* footer  */
.aguia-branca-image {
  width: 160px;
  margin: -.5rem 0 0 -1rem;
  object-fit: contain;
}

.reclame-aqui-image {
  width: 200px;
  height: 100px;
  object-fit: contain;
}

.Home {
  overflow-x: hidden;
}

/* banner home  */
@media screen and (max-width: 450px) {
  .banner-image {
    height: 180px;
    object-fit: cover;
  }
}