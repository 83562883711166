.pacote-detalhes h3 {
    font-size: calc(1.269rem + .228vw);
}

/* 
@media screen and (max-width: 480px) {
    .pacote-detalhes h3 {
        font-size: 20px;
    }
} */

/* @Ofertas  */
.pacote-texto-valor,
.pactote-texto-total {
    color: #063b7f;
}

#ofertas-pacote-card-container:hover .pacote-texto-valor,
#ofertas-pacote-card-container:hover .pactote-texto-total {
    color: #999;
}

#geral-pacote-card-container:hover .pacote-texto-valor,
#geral-pacote-card-container:hover .pactote-texto-total {
    color: #999;
}