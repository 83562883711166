.pacote-detalhes h3 {
    font-size: calc(1.269rem + .228vw);
}

.skeleton-container {
    position: relative;
    z-index: 5;
    animation: appears 300ms ease-in normal;
}

/* skeleton Styles  */
#skeleton-description,
#skeleton-title,
#skeleton-night,
#skeleton-image,
.skeleton-image,
#skeleton-price-title,
#skeleton-small-price,
#skeleton-price {
    color: transparent;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.skeleton-banner,
.header-container {
    min-height: 20vh;
    transition: all 1s ease-in-out;
}

/* home banner container traitment  */
@media screen and (min-width: 576px) {
    .skeleton-banner,
    .header-container {
        min-height: 22vh;
    }
}

@media screen and (min-width: 768px) {
    .skeleton-banner,
    .header-container {
        min-height: 30vh;
    }
}

@media screen and (min-width: 1024px) {
    .skeleton-banner,
    .header-container {
        min-height: 40vh;
    }
}

@media screen and (min-width: 1280px) {
    .skeleton-banner,
    .header-container {
        min-height: 50vh;
    }
}

@media screen and (min-width: 1400px) {
    .skeleton-banner,
    .header-container {
        min-height: 60vh;
    }
}

@media screen and (min-width: 1920px) {
    .skeleton-banner,
    .header-container {
        min-height: 70vh;
    }
}